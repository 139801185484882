import React, {useEffect, useState, useRef} from "react";

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHero  from "../components/template-partials/page-hero";
import FooterCrown from "../components/template-partials/footer-crown";
import InputMask from "react-input-mask";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faExternalLinkAlt, faCheck } from "@fortawesome/free-solid-svg-icons";


const ResourcesPage = () => {


  const checkboxRef = useRef(null);
  const [complete, setIsComplete] = useState(false);
  const [phone, setPhone] = useState();
  const [choice, setChoice] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(()=>{
    const hsh = typeof window !== "undefined" ? window.location.hash : ""
    const hv = ( hsh.split('#')[1] === 'complete' ? true : false );

    setIsComplete(hv);

  },[]);

  useEffect(()=>{
    const checkbox = checkboxRef.current;
    if (checkbox) {
      const isChecked = checkbox.checked;
    }
  },[isChecked]);

  return (
    <Layout route="resources">

      <Seo page="resources" title="Resources" description="Looking for systemic mastocytosis resources? Sign up to get updates about SM delivered to your inbox." />

      <div className="row lg_mt-3 mt-1 align-center medium-align-left">
        <div className="columns small-11 large-9">
          <h1 className="color--purple text--watch-quinn">Stay up to date on information about systemic mastocytosis and its treatment</h1>
        </div>
      </div>

      {!complete &&
        <div className="row lg_mt-4 mt-2 align-center medium-align-left">
          <div className="columns small-11 medium-expand">
            <p className="h3 color--purple strong">Sign up to be the first <br className="hide-for-medium"/>to know</p>
            <p className="h5 color--purple strong lg_mt-2 mt-2">All fields required  <br className="hide-for-medium"/> unless otherwise noted.</p>
          </div>
        </div>
      }

      {complete &&
        <div className="row lg_mt-4 mt-2 align-center medium-align-left">
          <div className="columns small-11 medium-expand">
            <p className="h3 color--purple strong">Your information has been successfully submitted.</p>
          </div>
        </div>
      }

      {!complete &&
        <form method="post" action="https://www.tfaforms.com/api_v2/workflow/processor">
          <div className="row lg_mt-4 mt-2 general-form align-center medium-align-left">

            <div className="columns small-11 large-6">
              <label className="full" htmlFor="tfa_1">First name</label>
              <input required id="tfa_1" name="tfa_1" type="text" placeholder="First name"/>
            </div>

            <div className="columns small-11 large-6 mt-2">
              <label className="full" htmlFor="tfa_2">Last name</label>
              <input required id="tfa_2" name="tfa_2" type="text" placeholder="Last name"/>
            </div>

            <div className="columns small-11 large-6 lg_mt-2 mt-2">
              <label className="full" htmlFor="tfa_4">Email</label>
              <input required id="tfa_4" name="tfa_4" type="email" placeholder="Email"/>
            </div>

            <div className="columns small-11 large-6 lg_mt-2 mt-2">
              <label className="full" htmlFor="tfa_5">Phone number</label>
              <InputMask
                type="tel"
                id="tfa_5"
                name="tfa_5"
                value={phone}
                mask="999-999-9999"
                maskchar=" "
                onChange={event => setPhone(event.target.value)}
                onKeyDown={event => setPhone(event.target.value)}
                placeholder="Phone number"
                required
              />
            </div>

            <div className="columns small-11 large-6 lg_mt-2 mt-2">
              <label className="full" htmlFor="tfa_6">NPI # (optional)</label>
              <div className="spinner-hider">
                <input id="tfa_6" name="tfa_6" type="number" placeholder="NPI # (optional)"/>
              </div>
              <a href="https://npiregistry.cms.hhs.gov/" target="_blank" className="lg_mt-1 mt-1 underborder color--purple" style={{display : `inline-block`}}>Look Up Your NPI #</a>
            </div>

            <div className="columns small-11 large-6 lg_mt-2 mt-2">
              <label className="full" htmlFor="tfa_7">ZIP code</label>
              <input required id="tfa_7" name="tfa_7" type="text" maxLength="5" pattern="[0-9]*" placeholder="ZIP code"/>
            </div>

            <div className="columns small-11 large-6 lg_mt-2 mt-2">
              <label className="full" htmlFor="tfa_8">Select a speciality</label>
              <div className="select-wrapper">
                <div className="tick"><FontAwesomeIcon className="color--powder" icon={faAngleDown}/></div>
                <select required id="tfa_8" name="tfa_8" defaultValue={"Select a speciality"} title="Speciality" onChange={(e) => setChoice(e.target.value)}>
                  <option selected value={choice} disabled>
                  Select a speciality
                  </option>
                  <option id="tfa_9" value="tfa_9">Physician/NP – Hematology Oncology</option>
                  <option id="tfa_10" value="tfa_10">Physician/NP – Allergy Immunology</option>
                  <option id="tfa_11" value="tfa_11">Physician/NP – Dermatology</option>
                  <option id="tfa_12" value="tfa_12">Physician/NP – Gastroenterology</option>
                  <option id="tfa_13" value="tfa_13">Nurse</option>
                  <option id="tfa_14" value="tfa_14">Physician Assistant</option>
                  <option id="tfa_15" value="tfa_15">Pharmacist</option>
                  <option id="tfa_16" value="tfa_16">Other Healthcare Professional</option>
                </select>
              </div>
            </div>

            <div className="columns small-11 medium-12 lg_mt-4 mt-2 relative">
              <label htmlFor="tfa_17" className="agreement">
                <span className="color--purple h5">
                  By checking this box, I confirm that I am a US healthcare professional and agree to receive information about Systemic Mastocytosis and current approved products of Blueprint Medicines related to Systemic Mastocytosis. I can opt-out from such communications by replying "Unsubscribe" to any email.
                  <br/><br/>
                  By clicking SIGN UP, I acknowledge that I have read and agree to Blueprint Medicines' <a href="https://www.blueprintmedicines.com/terms-of-use/" target="_blank" className="a--std no-ext">Terms of Use</a>.
                  <br/><br/>
                  To learn more about Blueprint Medicines’ privacy practices, including how we process, share, and protect your Personal Information, please read our <a href="https://www.blueprintmedicines.com/privacy-policy/" target="_blank" className="a--std no-ext">Privacy Notice</a>.
                </span>
              </label>
              <input
                className="agreement-box"
                ref={checkboxRef}
                id="tfa_17"
                name="tfa_17"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div className="checker">
                <FontAwesomeIcon className="color--powder" icon={faCheck}/>
              </div>
            </div>
            <input type="hidden" value="5020344" name="tfa_dbFormId" id="tfa_dbFormId"/>
            <input type="hidden" value="" name="tfa_dbResponseId" id="tfa_dbResponseId"/>
            <input type="hidden" value="7cdf5492b5bd43f37118d3ad4ed27e19" name="tfa_dbControl" id="tfa_dbControl"/>
            <input type="hidden" value="" name="tfa_dbWorkflowSessionUuid" id="tfa_dbWorkflowSessionUuid"/>
            <input type="hidden" value="1" name="tfa_dbVersionId" id="tfa_dbVersionId"/>
            <input type="hidden" value="" name="tfa_switchedoff" id="tfa_switchedoff"/>
            <div style={{display : `none`, visibility : `hidden`}}>
              <input type="checkbox" value="tfa_18" className="" checked data-default-value="true" id="tfa_18" name="tfa_18" aria-labelledby="tfa_18-L" data-tfa-labelledby="tfa_17-L tfa_18-L"/>
              <input type="checkbox" value="tfa_22" className="" checked data-default-value="true" id="tfa_22" name="tfa_22" aria-labelledby="tfa_22-L" data-tfa-labelledby="tfa_21-L tfa_22-L"/>
              <input type="checkbox" value="tfa_20" className="" checked data-default-value="true" id="tfa_20" name="tfa_20" aria-labelledby="tfa_20-L" data-tfa-labelledby="tfa_19-L tfa_20-L"/>
              <input type="text" id="tfa_24" name="tfa_24" value="SuspectSM.com" default="SuspectSM.com" title="Lead Source" className=""/>
            </div>
            <div className="columns small-12 large-12 lg_mt-4 text-center">
              <button type="submit" className="btn btn--rose btn--small-fw uppercase mt-1">Sign up</button>
            </div>

          </div>
        </form>
      }

      <div className="row lg_mt-6 mt-1 mb-3 pt-6 lg_mb-6 align-middle small-collapse medium-uncollapse">
        <div className="columns small-11">
          <div className="callout-rounded callout-rounded--blue callout-rounded--sml callout-rounded--left lg_pt-5 lg_pb-5">

            {/*inner */}
              <div className="row align-middle small-collapse medium-uncollapse">

                <div className="columns small-12 medium-expand text-left lg_mt-2">
                  <div className="row">
                    <div className="columns small-12 large-10 large-offset-1">
                      <p className="h3 sb color--purple strong">
                        Helpful resources
                      </p>
                      <p className="color--purple lg_mt-1 mt-1">These independent advocacy groups are included as a reference for patients and do not imply endorsement of the company or its products by the groups listed. Blueprint Medicines is not affiliated with and does not endorse any particular advocacy group. We make no guarantees about the accuracy of the information provided on advocacy groups&rsquo; websites or the quality <br className="show-for-large"/> of support provided.</p>
                    </div>
                  </div>
                </div>

                <div className="columns small-12 relative stretch medium-shrink">
                  <div className="hr hide-for-medium"></div>
                  <div className="vr show-for-medium"></div>
                </div>

                <div className="columns small-12 medium-expand mt-3">
                  <div className="row align-center">
                    <div className="columns large-10">
                      <div className="h5">
                        <div><a className="underborder color--purple" href="https://allergyasthmanetwork.org/" target="_blank">Allergy & Asthma Network</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/></div>
                      </div>
                      <div className="h5 lg_mt-3 mt-3">
                        <div><a className="underborder color--purple" href="https://www.aafa.org/" target="_blank">Asthma and Allergy<br/>Foundation of America</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/></div>
                      </div>
                      <div className="h5 lg_mt-3 mt-3">
                        <div><a className="underborder color--purple" href="https://www.cancersupportcommunity.org/" target="_blank">Cancer Support Community</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/></div>
                      </div>
                      <div className="h5 lg_mt-3 mt-3">
                        <div><a className="underborder color--purple" href="https://globalgenes.org/" target="_blank">Global Genes</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/></div>
                      </div>
                      <div className="h5 lg_mt-3 mt-3">
                        <div><a className="underborder color--purple" href="https://www.lls.org/" target="_blank">Leukemia & Lymphoma Society</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/></div>
                      </div>
                      <div className="h5 lg_mt-3 mt-3">
                        <a className="underborder color--purple" href="https://tmsforacure.org/" target="_blank">The Mast Cell Disease Society</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/>
                      </div>
                      <div className="h5 lg_mt-3 mt-3">
                        <div><a className="underborder color--purple" href="https://rarediseases.org/" target="_blank">NORD</a> <FontAwesomeIcon className="color--powder ex-link" icon={faExternalLinkAlt}/></div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            {/*inner */}

          </div>
        </div>
      </div>



      <FooterCrown type="resources"/>

    </Layout>
  )
}

export default ResourcesPage
